import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { Box, Button, Stepper, Step, StepLabel, TextField, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Grid, MenuItem, Select, InputLabel, FormLabel, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import Swal from 'sweetalert2';
import de from 'date-fns/locale/de';

const steps = ['Kundendaten', 'Vollmacht', 'BAFA Unterlagen'];

const initialFormData = {

    user_form: '',

    kundecode: '',

    kundendaten: {
        berateremail: '',
        email: '',
        name: '',
        vorname: '',
        firma: '',
        plz: '',
        ort: '',
        adresse: '',
        telefonnummer: '',
        rechtsform: '',
        steuernummer_unternehmens: '',
        iban: '',
        geschaftsgegenstand_unternehmen: '',
        bruttoumsatz_vorjahr: '',
        anzahl_mitarbeiter: '',
        bilanzsumme: '',
        grundungsdatum: '',
        unternehmen_vorsteuerabzugsberechtigt: '',
        hatFoerdermittelErhalten: '',
        details_Fordermitteln: [{
            datum_bescheid: '',
            zuwendungsstelle: '',
            aktenzeichen: '',
            art_beihilfe: '',
            betrag: ''
        }]
    },

    beraterdaten: {
        name: '',
        vorname: '',
        telefonnummer: '',
        firma: '',
        plz: '',
        adresse: '',
        ort: '',

    },

    bafa: {
        bafa_id: '',
        datum_zuwendungsbescheid: '',
        vorgangsnummer_zuwendungsbescheid: '',
        unternehmenstyp: '',
        gewerblichen_strassenguterverkehrs: '',
        genderCompetence: {
            fortbildung: false,
            sensibilisierung: false,
            nein: false,
        },
        genderParity: {
            teams: false,
            partner: false,
            nein: false,
        },
        genderSensitiveApproaches: {
            ansprache: false,
            didaktisch: false,
            beruf: false,
            digital: false,
            nein: false,
        }
    },
}

function MultiStepForm() {
    const [activeStep, setActiveStep] = useState(0);
    const location = useLocation();
    const [token, setToken] = useState("");

    const [formData, setFormData] = useState(initialFormData);

    const [initialState, setInitialState] = useState(formData);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const view = queryParams.get('view');
        const code = queryParams.get('code');


        if (view === 'beraterdaten') {
            setActiveStep(1);  // Step 1 for Beraterdaten
            setFormData({
                ...formData,
                kundecode: code,
                user_form: view
            })
        } else if (view === 'bafa') {
            setActiveStep(2);  // Step 2 for BAFA
            setFormData({
                ...formData,
                kundecode: code,
                user_form: view
            })
        } else if (view === 'kundendaten' || !view) {
            setActiveStep(0);  // Default to Step 0 for Kundendaten or no parameter
            setFormData({
                ...formData,
                user_form: 'kundendaten'
            })
        }
    }, [location.search]);  // Dependency on location.search to update when URL changes

    const resetForm = () => {
        setFormData(initialFormData);
    };

    const checkFormValidity = () => {
        const { kundendaten, beraterdaten, bafa } = formData;

        switch (activeStep) {
            case 0:
                // Check if all required fields are filled except 'bilanzsumme'
                const allFieldsFilled_kunde = Object.keys(kundendaten).every(key => {
                    // Allow 'bilanzsumme' to be empty
                    return key === 'bilanzsumme' || kundendaten[key] !== '';
                });

                // Check if 'hatFoerdermittelErhalten' is 'ja', then 'details_Fordermitteln' should not be empty
                const hasValidFördermittel =
                    kundendaten.hatFoerdermittelErhalten === 'Ja' ?
                        kundendaten.details_Fordermitteln.every(item => Object.values(item).every(innerValue => innerValue.trim() !== '')) :
                        true;

                return allFieldsFilled_kunde && hasValidFördermittel;

            case 1:
                // Check if all required fields are filled
                const allFieldsFilled_berater = Object.values(beraterdaten).every(value => {
                    return value !== '';
                });

                return allFieldsFilled_berater;

            case 2:
                // Check if all required fields are filled
                const isAtLeastOneTrue = (obj) => {
                    return Object.values(obj).includes(true);
                };

                // Check if all required fields are filled and at least one value is true in each object
                const allFieldsFilled_bafa = Object.values(bafa).every(value => {
                    // Ensure that all fields are not empty or false
                    if (typeof value === 'object') {
                        // For nested objects like genderCompetence, genderParity, and genderSensitiveApproaches
                        return isAtLeastOneTrue(value);
                    }
                    return value !== '';
                });

                return allFieldsFilled_bafa;

            default:
                alert('Error occurred, try reloading the page and starting the process again. If error persists, contact administrator.');
                return false;  // Return false for any undefined step
        }
    };

    const base64UrlEncode = (input) => {
        return btoa(JSON.stringify(input))
            .replace(/=/g, "")
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
    };

    const generateSignature = async (encodedHeaderPayload, secretKey) => {
        const encoder = new TextEncoder();
        const keyData = encoder.encode(secretKey);

        // Import secret key for HMAC-SHA256 algorithm
        const cryptoKey = await crypto.subtle.importKey(
            "raw",
            keyData,
            { name: "HMAC", hash: { name: "SHA-256" } },
            false,
            ["sign"]
        );

        // Create HMAC signature
        const signatureBuffer = await crypto.subtle.sign(
            "HMAC",
            cryptoKey,
            encoder.encode(encodedHeaderPayload)
        );

        return btoa(String.fromCharCode(...new Uint8Array(signatureBuffer)))
            .replace(/=/g, "")
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (activeStep) {
            case 0:
                setFormData({
                    ...formData,
                    kundendaten: {
                        ...formData.kundendaten,
                        [name]: value,
                    }
                });
                break;

            case 1:
                setFormData({
                    ...formData,
                    beraterdaten: {
                        ...formData.beraterdaten,
                        [name]: value,
                    }
                });
                break;
            case 2:
                setFormData({
                    ...formData,
                    bafa: {
                        ...formData.bafa,
                        [name]: value,
                    }
                });
                break;

            default:
                alert('error occur try reloading the page and starting the process again. if error persist contact administrator.')
                return null;

        }



    };

    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');  // Ensure two digits for day
        const month = (d.getMonth() + 1).toString().padStart(2, '0');  // Month is 0-indexed, so add 1
        const year = d.getFullYear();
        return `${day}.${month}.${year}`;
    };


    const handleDateChange = (name, value) => {


        const formattedDate = formatDate(value);

        switch (activeStep) {
            case 0:

                setFormData({
                    ...formData,
                    kundendaten: {
                        ...formData.kundendaten,
                        [name]: formattedDate,
                    }
                });
                break;

            case 1:
                setFormData({
                    ...formData,
                    beraterdaten: {
                        ...formData.beraterdaten,
                        [name]: formattedDate,
                    }
                });
                break;
            case 2:
                setFormData({
                    ...formData,
                    bafa: {
                        ...formData.bafa,
                        [name]: formattedDate,
                    }
                });
                break;
            default:
                alert('error occur try reloading the page and starting the process again. if error persist contact administrator.')
                return null;

        }



    };

    const handleSubsidyDateChange = (newValue, index) => {
        const newSubsidies = [...formData.kundendaten.details_Fordermitteln];
        newSubsidies[index].datum_bescheid = formatDate(newValue);
        setFormData({
            ...formData,
            kundendaten: {
                ...formData.kundendaten,
                details_Fordermitteln: newSubsidies
            },
        });
    };

    const handleSubsidyChange = (index, e) => {
        const { name, value } = e.target;
        const newSubsidies = [...formData.kundendaten.details_Fordermitteln];
        newSubsidies[index][name] = value;
        setFormData({
            ...formData,
            kundendaten: {
                ...formData.kundendaten,
                details_Fordermitteln: newSubsidies
            },
        });
    };

    const addSubsidyRow = () => {

        setFormData({
            ...formData,
            kundendaten: {
                ...formData.kundendaten,
                details_Fordermitteln: [...formData.kundendaten.details_Fordermitteln, {
                    datum_bescheid: '',
                    zuwendungsstelle: '',
                    aktenzeichen: '',
                    art_beihilfe: '',
                    betrag: ''
                }],
            }
        });
    };

    const handleSubmit = async () => {

        const { kundendaten, beraterdaten, bafa } = formData;

        let response;

        let payload = {
            "session_id": Date.now(),
            "[user.form]": formData.user_form,
            "[Kunde.Company]": kundendaten.firma,
            "[Kunde.FirstName]": kundendaten.vorname,
            "[Kunde.LastName]": kundendaten.name,
            "[Kunde.StreetAddress]": kundendaten.adresse,
            "[Kunde.PostalCode]": kundendaten.plz,
            "[Kunde.City]": kundendaten.ort,
            "[Kunde.Email]": kundendaten.email,
            "[Kunde.phonenumber]": kundendaten.telefonnummer,
            "[Kunde.Jahresumsatz]": kundendaten.bruttoumsatz_vorjahr,
            "[Kunde.BruttoumsatzVorjahr]": kundendaten.bruttoumsatz_vorjahr,
            "[Kunde.Mitarbeiterzahl]": kundendaten.anzahl_mitarbeiter,
            "[Kunde.Bilanzsumme]": kundendaten.bilanzsumme,
            "[Kunde.RechtsformJuris]": kundendaten.rechtsform,
            "[Kunde.vorsteuerJa]": kundendaten.unternehmen_vorsteuerabzugsberechtigt === "Ja" ? "x" : "",
            "[Kunde.vorsteurerNein]": kundendaten.unternehmen_vorsteuerabzugsberechtigt === "Nein" ? "x" : "",
            "[Kunde.grundungsdaten]": kundendaten.grundungsdatum,
            "[Kunde.steuernummer]": kundendaten.steuernummer_unternehmens,
            "[Kunde.bankkonto]": kundendaten.iban,
            "[Kunde.ggstand]": kundendaten.geschaftsgegenstand_unternehmen,
            "[Kunde.minimisJa]": kundendaten.hatFoerdermittelErhalten === "Ja" ? "x" : "",
            "[Kunde.minimisNein]": kundendaten.hatFoerdermittelErhalten === "Nein" ? "x" : "",
            "[Kunde.detailsFordermitteln]": kundendaten.details_Fordermitteln,
            "[Berater.Code]": formData.kundecode,
            "[Berater.LastName]": beraterdaten.name,
            "[Berater.FirstName]": beraterdaten.vorname,
            "[Berater.phonenumber]": beraterdaten.telefonnummer,
            "[Berater.Company]": beraterdaten.firma,
            "[Berater.PostalCode]": beraterdaten.plz,
            "[Berater.StreetAddress]": beraterdaten.adresse,
            "[Berater.Email]": kundendaten.berateremail,
            "[Berater.City]": beraterdaten.ort,
            "[Berater.BAFAID]": bafa.bafa_id,
            "[Bafa.Zuwendungsdatum]": bafa.datum_zuwendungsbescheid,
            "[UBF.NR]": bafa.vorgangsnummer_zuwendungsbescheid,
            "[Bafa.Unternehmenstyp.eigenstaendigesUnternehmen]": bafa.unternehmenstyp === "eigenstaendiges-unternehmen" ? "x" : "",
            "[Bafa.Unternehmenstyp.partnerunternehmen]": bafa.unternehmenstyp === "partnerunternehmen" ? "x" : "",
            "[Bafa.Unternehmenstyp.verbundenesUnternehmen]": bafa.unternehmenstyp === "verbundenes-unternehmen" ? "x" : "",
            "[Bafa.GewerblichenVerkehr]": bafa.gewerblichen_strassenguterverkehrs,
            "[Bafa.GenderCompetence.Fortbildung]": bafa.genderCompetence.fortbildung ? "x" : "",
            "[Bafa.GenderCompetence.Sensibilisierung]": bafa.genderCompetence.sensibilisierung ? "x" : "",
            "[Bafa.GenderCompetence.Nein]": bafa.genderCompetence.nein ? "x" : "",
            "[Bafa.GenderParity.Teams]": bafa.genderParity.teams ? "x" : "",
            "[Bafa.GenderParity.Partner]": bafa.genderParity.partner ? "x" : "",
            "[Bafa.GenderParity.Nein]": bafa.genderParity.nein ? "x" : "",
            "[Bafa.GenderSensitiveApproaches.Ansprache]": bafa.genderSensitiveApproaches.ansprache ? "x" : "",
            "[Bafa.GenderSensitiveApproaches.Didaktisch]": bafa.genderSensitiveApproaches.didaktisch ? "x" : "",
            "[Bafa.GenderSensitiveApproaches.Beruf]": bafa.genderSensitiveApproaches.beruf ? "x" : "",
            "[Bafa.GenderSensitiveApproaches.Digital]": bafa.genderSensitiveApproaches.digital ? "x" : "",
            "[Bafa.GenderSensitiveApproaches.Nein]": bafa.genderSensitiveApproaches.nein ? "x" : "",
        };

        const secretKey = 'Merkel';
        const header = { alg: "HS256", typ: "JWT" };

        const encodedHeader = base64UrlEncode(header);
        const encodedPayload = base64UrlEncode(payload);
        const encodedHeaderPayload = `${encodedHeader}.${encodedPayload}`;

        const signature = await generateSignature(encodedHeaderPayload, secretKey);

        const jwt = `${encodedHeaderPayload}.${signature}`;
        const missingFields = [];
        setToken(jwt);

        switch (activeStep) {
            case 0:



                if (!formData.kundendaten.berateremail) missingFields.push('Berater Email');
                if (!formData.kundendaten.email) missingFields.push('Deine Email-Adresse');
                if (!formData.kundendaten.name) missingFields.push('Name');
                if (!formData.kundendaten.vorname) missingFields.push('Vorname');
                if (!formData.kundendaten.telefonnummer) missingFields.push('Telefonnummer');
                if (!formData.kundendaten.firma) missingFields.push('Firma');
                if (!formData.kundendaten.adresse) missingFields.push('Straße, Hausnummer');
                if (!formData.kundendaten.plz) missingFields.push('PLZ');
                if (!formData.kundendaten.ort) missingFields.push('Ort');
                if (!formData.kundendaten.rechtsform) missingFields.push('Rechtsform');
                if (!formData.kundendaten.steuernummer_unternehmens) missingFields.push('Steuernummer des Unternehmens');
                if (!formData.kundendaten.geschaftsgegenstand_unternehmen) missingFields.push('Geschäftsgegenstand des Unternehmens');
                if (!formData.kundendaten.iban) missingFields.push('Kontoverbindung (IBAN)');
                if (!formData.kundendaten.bruttoumsatz_vorjahr) missingFields.push('Bruttoumsatz Vorjahr');
                if (!formData.kundendaten.anzahl_mitarbeiter) missingFields.push('Anzahl Mitarbeiter');
                if (!formData.kundendaten.grundungsdatum) missingFields.push('Gründungsdatum');
                if (!formData.kundendaten.unternehmen_vorsteuerabzugsberechtigt) missingFields.push('Vorsteuerabzugsberechtigung des Unternehmens');
                if (!formData.kundendaten.hatFoerdermittelErhalten) missingFields.push('Angabe, ob Fördermittel erhalten wurden');
                if (formData.kundendaten.hatFoerdermittelErhalten === 'Ja') {
                    if (formData.kundendaten.details_Fordermitteln && formData.kundendaten.details_Fordermitteln.length > 0) {
                        formData.kundendaten.details_Fordermitteln.forEach((detail, index) => {
                            if (!detail.datum_bescheid) missingFields.push(`Fördermittel Detail ${index + 1}: Datum des Bescheids`);
                            if (!detail.zuwendungsstelle) missingFields.push(`Fördermittel Detail ${index + 1}: Zuwendungsstelle`);
                            if (!detail.aktenzeichen) missingFields.push(`Fördermittel Detail ${index + 1}: Aktenzeichen`);
                            if (!detail.art_beihilfe) missingFields.push(`Fördermittel Detail ${index + 1}: Art der Beihilfe`);
                            if (!detail.betrag) missingFields.push(`Fördermittel Detail ${index + 1}: Betrag`);
                        });
                    }
                }


                // Show alert if there are missing fields
                if (missingFields.length > 0) {
                    const listItems = missingFields.map((field, index) => `${index + 1}. ${field}`).join('<br>');
                    Swal.fire({
                        title: 'Bitte fehlende Felder ausfüllen!',
                        html: `<div style="text-align:left; color:red;">${listItems}</div>`,
                        icon: 'warning',
                        showCloseButton: true,
                        showConfirmButton: false,
                    });
                    break;
                } else {
                    // Make the API request
                    await axios.post(`https://bafa-beratung.wv-consult.de/api/v1/awtexcel`, { field: jwt });

                    await Swal.fire({
                        title: 'Die Email wurde erfolgreich versendet!',
                        text: 'Der Berater wird sich mit Ihnen in Verbindung setzen.',
                        icon: 'success',
                        showConfirmButton: false,
                        showCloseButton: true,
                        timer: 5000
                    });


                    window.location.reload()
                    break;
                }



            case 1:

                // Check for missing fields in beraterdaten
                if (!formData.beraterdaten.name) missingFields.push('Name');
                if (!formData.beraterdaten.vorname) missingFields.push('Vorname');
                if (!formData.beraterdaten.telefonnummer) missingFields.push('Telefonnummer');
                if (!formData.beraterdaten.firma) missingFields.push('Firma');
                if (!formData.beraterdaten.plz) missingFields.push('PLZ');
                if (!formData.beraterdaten.adresse) missingFields.push('Adresse');
                if (!formData.beraterdaten.ort) missingFields.push('Ort');

                if (missingFields.length > 0) {
                    const listItems = missingFields.map(field => `- ${field}`).join('<br>');
                    Swal.fire({
                        title: 'Bitte fehlende Felder ausfüllen!',
                        html: `<div style="text-align:left; color:red;">${listItems}</div>`,
                        icon: 'warning',
                        showCloseButton: true,
                        showConfirmButton: false,
                    });
                    break;
                } else {
                    // Show loading spinner while generating the document
                    Swal.fire({
                        title: 'Dokument wird erstellt!',
                        text: 'Bitte warten Sie, während das Dokument erstellt wird.',
                        icon: 'info',
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    // Make the API request to generate the file
                    const response = await axios.post('https://bafa-beratung.wv-consult.de/api/v1/awtexcel', { field: jwt }, {
                        responseType: 'blob',  // Expecting the response to be a file
                    });

                    // Create a URL for the Blob and trigger the download
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '01 Vollmacht BAFA.pdf');  // Filename for the downloaded file
                    document.body.appendChild(link);  // Append link to the body
                    link.click();  // Programmatically click the link to trigger download
                    link.remove();  // Clean up the link element after download

                    // Optionally, release the Blob URL
                    window.URL.revokeObjectURL(url);

                    // Success message after document creation
                    await Swal.fire({
                        title: 'Dokument wird erstellt!',
                        text: 'Das Dokument wird in Kürze heruntergeladen.',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 5000
                    });
                    window.location.reload()

                    break;
                }


            case 2:


                // Check for BAFA fields
                if (!formData.bafa.bafa_id) missingFields.push('BAFA ID');
                if (!formData.bafa.datum_zuwendungsbescheid) missingFields.push('Datum des Zuwendungsbescheids');
                if (!formData.bafa.vorgangsnummer_zuwendungsbescheid) missingFields.push('Vorgangsnummer des Zuwendungsbescheids');
                if (!formData.bafa.unternehmenstyp) missingFields.push('Unternehmenstyp');
                if (!formData.bafa.gewerblichen_strassenguterverkehrs) missingFields.push('Gewerblicher Straßengüterverkehr');

                // Check if at least one option from each gender-related field is checked
                let missingGenderFields = false;

                if (!formData.bafa.genderCompetence.fortbildung && !formData.bafa.genderCompetence.sensibilisierung && !formData.bafa.genderCompetence.nein) {
                    missingGenderFields = true;
                    missingFields.push('Mindestens eine Auswahl aus Genderkompetenz muss getroffen werden');
                }

                if (!formData.bafa.genderParity.teams && !formData.bafa.genderParity.partner && !formData.bafa.genderParity.nein) {
                    missingGenderFields = true;
                    missingFields.push('Mindestens eine Auswahl aus Genderparität muss getroffen werden');
                }

                if (!formData.bafa.genderSensitiveApproaches.ansprache && !formData.bafa.genderSensitiveApproaches.didaktisch && !formData.bafa.genderSensitiveApproaches.beruf && !formData.bafa.genderSensitiveApproaches.digital && !formData.bafa.genderSensitiveApproaches.nein) {
                    missingGenderFields = true;
                    missingFields.push('Mindestens eine Auswahl aus Gender-sensiblen Ansätzen muss getroffen werden');
                }

                console.log(missingFields)

                // Show alert if there are missing fields
                if (missingFields.length > 0) {
                    const listItems = missingFields.map(field => `- ${field}`).join('<br>');

                    Swal.fire({
                        title: 'Bitte fehlende Felder ausfüllen!',
                        html: `<div style="text-align:left; color:red;">${listItems}</div>`,
                        icon: 'warning',
                        showCloseButton: true,
                        showConfirmButton: false,
                    });
                    break;
                } else {
                    // Show loading spinner while generating the ZIP file
                    Swal.fire({
                        title: 'ZIP-Datei wird erstellt!',
                        text: 'Bitte warten Sie, während die ZIP-Datei erstellt wird.',
                        icon: 'info',
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    // Make the API request to generate the ZIP file
                    const zipResponse = await axios.post('https://bafa-beratung.wv-consult.de/api/v1/awtexcel', { field: jwt }, {
                        responseType: 'blob',  // Expecting the response to be a ZIP file
                    });

                    // Create a URL for the Blob and trigger the download
                    const zipUrl = window.URL.createObjectURL(new Blob([zipResponse.data]));
                    const zipLink = document.createElement('a');
                    zipLink.href = zipUrl;
                    zipLink.setAttribute('download', 'BAFA Unterlagen.zip');  // Filename for the downloaded ZIP file
                    document.body.appendChild(zipLink);  // Append link to the body
                    zipLink.click();  // Programmatically click the link to trigger download
                    zipLink.remove();  // Clean up the link element after download

                    // Optionally, release the Blob URL
                    window.URL.revokeObjectURL(zipUrl);

                    // Success message after ZIP creation
                    await Swal.fire({
                        title: 'ZIP-Datei wird erstellt!',
                        text: 'Die ZIP-Datei wird in Kürze heruntergeladen.',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 5000,
                    })

                    window.location.reload()

                    break;
                }

            default:
                // Handle unknown step or errors
                Swal.fire({
                    title: 'Fehler aufgetreten',
                    text: 'Bitte laden Sie die Seite neu und versuchen Sie es erneut. Kontaktieren Sie den Administrator, falls das Problem weiterhin besteht.',
                    icon: 'error',
                    confirmButtonText: 'Seite neu laden'
                })
        }



    }

    const handleMultipleSelection = (section, itemName, value) => {
        setFormData({
            ...formData,
            bafa: {
                ...formData.bafa,
                [section]: {
                    ...formData.bafa[section],
                    [itemName]: value,
                },
            },
        });
    };


    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <Typography variant="h6">EMail-Adresse deines Beraters:</Typography>
                        <TextField
                            label="Berater Email"
                            name="berateremail"
                            value={formData.kundendaten.berateremail}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            type='email'
                            required
                        />
                        <Typography variant="h6" sx={{ marginTop: 2 }}>Deine Daten:</Typography>

                        <TextField
                            label="Deine Email-Adresse"
                            name="email"
                            value={formData.kundendaten.email}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            type='email'
                            required
                        />
                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={formData.kundendaten.name}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Vorname"
                                    name="vorname"
                                    value={formData.kundendaten.vorname}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Telefonnummer"
                                    name="telefonnummer"
                                    value={formData.kundendaten.telefonnummer}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    type='tel'
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Firma"
                                    name="firma"
                                    value={formData.kundendaten.firma}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Straße, Hausnummer"
                                    name="adresse"
                                    value={formData.kundendaten.adresse}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="PLZ"
                                    name="plz"
                                    value={formData.kundendaten.plz}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    type='number'
                                    required
                                    inputProps={{
                                        maxLength: 5, // Limit input to 5 digits
                                    }}
                                    onInput={(e) => {
                                        if (e.target.value.length > 5) {
                                            e.target.value = e.target.value.slice(0, 5); // Ensure no more than 5 digits are entered
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Ort"
                                    name="ort"
                                    value={formData.kundendaten.ort}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="demo-simple-select-label">Rechtsform</InputLabel>

                                    <Select
                                        name="rechtsform"
                                        value={formData.kundendaten.rechtsform}
                                        onChange={handleChange}
                                        label="Rechtsform"
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value="Naturliche Person">Natürliche Person</MenuItem>
                                        <MenuItem value="Juristische Person">Juristische Person</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="Steuernummer des Unternehmens"
                                        name="steuernummer_unternehmens"
                                        value={formData.kundendaten.steuernummer_unternehmens}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Was ist der Geschäftsgegenstand des Unternehmens?"
                                    name="geschaftsgegenstand_unternehmen"
                                    value={formData.kundendaten.geschaftsgegenstand_unternehmen}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={5}
                                    required
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Kontoverbindung (IBAN)"
                                    name="iban"
                                    value={formData.kundendaten.iban}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Bruttoumsatz Vorjahr"
                                    name="bruttoumsatz_vorjahr"
                                    value={formData.kundendaten.bruttoumsatz_vorjahr}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Anzahl Mitarbeiter"
                                    name="anzahl_mitarbeiter"
                                    value={formData.kundendaten.anzahl_mitarbeiter}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Bilansumme (kein Pflichtfeld)"
                                    name="bilanzsumme"
                                    value={formData.kundendaten.bilanzsumme}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ width: '100%' }} adapterLocale={de}>
                                    <DatePicker
                                        label="Gründungsdatum (TT.MM.JJJJ)"
                                        onChange={(e) => handleDateChange('grundungsdatum', e)}
                                        required
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                margin="normal"
                                                sx={{ width: '100%' }}
                                            />
                                        )}
                                        maxDate={new Date()} // Prevent future dates
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth margin="normal">
                                <Typography variant="body1">Ist das Unternehmen vorsteuerabzugsberechtigt?</Typography>
                                <RadioGroup
                                    row
                                    name="unternehmen_vorsteuerabzugsberechtigt"
                                    value={formData.kundendaten.unternehmen_vorsteuerabzugsberechtigt}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="Ja" control={<Radio />} label="Ja" />
                                    <FormControlLabel value="Nein" control={<Radio />} label="Nein" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth margin="normal">
                                <Typography variant="body1">Hat das Unternehmen die letzten 2 Jahre nicht rückzahlungspflichtige Fördermittel oder De-Minimis-Beihilfen erhalten?</Typography>
                                <RadioGroup
                                    row
                                    name="hatFoerdermittelErhalten"
                                    value={formData.kundendaten.hatFoerdermittelErhalten}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="Ja" control={<Radio />} label="Ja" />
                                    <FormControlLabel value="Nein" control={<Radio />} label="Nein" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {formData.kundendaten.hatFoerdermittelErhalten === 'Ja' && (
                            <Box>
                                <Typography variant="h6" sx={{ marginTop: 2 }}>Details zu den Fördermitteln</Typography>
                                {formData.kundendaten.details_Fordermitteln.map((details_Fordermitteln, index) => (
                                    <Box key={index} sx={{
                                        borderBottom: '1px solid #1976d2',
                                        paddingTop: '10px',
                                        paddingBottom: '20px',
                                        marginBottom: '10px'

                                    }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                                                    <DatePicker
                                                        label="Datum des Bescheids"
                                                        name='datum_bescheid'
                                                        onChange={(newValue) => handleSubsidyDateChange(newValue, index)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                margin="normal"
                                                                value={details_Fordermitteln.datum_bescheid}
                                                            />
                                                        )}
                                                        maxDate={new Date()} // Prevent future dates
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Zuwendungsstelle"
                                                    name="zuwendungsstelle"
                                                    value={details_Fordermitteln.zuwendungsstelle}
                                                    onChange={(e) => handleSubsidyChange(index, e)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Aktenzeichen"
                                                    name="aktenzeichen"
                                                    value={details_Fordermitteln.aktenzeichen}
                                                    onChange={(e) => handleSubsidyChange(index, e)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Art der Beihilfe"
                                                    name="art_beihilfe"
                                                    value={details_Fordermitteln.art_beihilfe}
                                                    onChange={(e) => handleSubsidyChange(index, e)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Betrag"
                                                    name="betrag"
                                                    value={details_Fordermitteln.betrag}
                                                    onChange={(e) => handleSubsidyChange(index, e)}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                                <Button variant="outlined" color="primary" onClick={addSubsidyRow}
                                    sx={{
                                        marginTop: '10px',

                                    }}>Weitere Fördermittel hinzufügen</Button>
                            </Box>
                        )}
                        <br />
                        <Button onClick={handleSubmit} fullWidth variant='outlined'>Senden</Button>

                    </Box>
                );
            case 1:
                return (
                    <Box>
                        <Typography variant="h6">Beraterdaten:</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={formData.beraterdaten.name}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Vorname"
                                    name="vorname"
                                    value={formData.beraterdaten.vorname}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Telefonnummer"
                                    name="telefonnummer"
                                    value={formData.beraterdaten.telefonnummer}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    type='tel'
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Firma"
                                    name="firma"
                                    value={formData.beraterdaten.firma}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Straße, Hausnummer"
                                    name="adresse"
                                    value={formData.beraterdaten.adresse}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Postleitzahl"
                                    name="plz"
                                    value={formData.beraterdaten.plz}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    type='number'
                                    required
                                    inputProps={{
                                        maxLength: 5, // Limit input to 5 digits
                                    }}
                                    onInput={(e) => {
                                        if (e.target.value.length > 5) {
                                            e.target.value = e.target.value.slice(0, 5); // Ensure no more than 5 digits are entered
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Ort"
                                    name="ort"
                                    value={formData.beraterdaten.ort}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Button onClick={handleSubmit} fullWidth variant='outlined'>Vollmacht BAFA herunterladen</Button>
                    </Box>
                );
            case 2:
                return (

                    <Box>

                        {/* Bafa ID */}
                        <TextField
                            label="Bafa ID"
                            name='bafa_id'
                            value={formData.bafa.bafa_id}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            type='number'
                            required
                        />


                        <Grid item xs={12} margin='15px 0px 15px 0'>
                            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ width: '100%' }} adapterLocale={de}>
                                <DatePicker
                                    label="Datum Zuwendungsbescheid (TT.MM.JJJJ)"
                                    onChange={(e) => handleDateChange('datum_zuwendungsbescheid', e)}
                                    required
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="normal"
                                            sx={{ width: '100%' }}
                                        />
                                    )}
                                    maxDate={new Date()} // Prevent future dates
                                />
                            </LocalizationProvider>
                        </Grid>


                        {/* Vorgangsnummer (UBF) gem. Zuwendungsbescheid */}
                        <TextField
                            label="Vorgangsnummer (UBF) gem. Zuwendungsbescheid"
                            name='vorgangsnummer_zuwendungsbescheid'
                            value={formData.bafa.vorgangsnummer_zuwendungsbescheid}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                        />

                        {/* Unternehmenstyp */}
                        <FormControl fullWidth variant="outlined" margin="normal" required>
                            <InputLabel>Unternehmenstyp</InputLabel>
                            <Select label="Unternehmenstyp" defaultValue=""
                                name='unternehmenstyp'
                                value={formData.bafa.unternehmenstyp}
                                onChange={handleChange}
                            >
                                <MenuItem value="eigenstaendiges-unternehmen">Eigenständiges Unternehmen</MenuItem>
                                <MenuItem value="partnerunternehmen">Partnerunternehmen</MenuItem>
                                <MenuItem value="verbundenes-unternehmen">Verbundenes Unternehmen</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Ist das Unternehmen im Bereich des gewerblichen Straßengüterverkehrs tätig? */}
                        {/* Ist das Unternehmen im Bereich des gewerblichen Straßengüterverkehrs tätig? */}
                        <FormControl component="fieldset" margin="normal" required>
                            <FormLabel component="legend">Ist das Unternehmen im Bereich des gewerblichen Straßengüterverkehrs tätig?</FormLabel>
                            <RadioGroup row
                                name='gewerblichen_strassenguterverkehrs'
                                value={formData.bafa.gewerblichen_strassenguterverkehrs}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="ja" control={<Radio />} label="Ja" />
                                <FormControlLabel value="nein" control={<Radio />} label="Nein" />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <Typography variant="h8" style={{ fontWeight: 'bold', color: 'black' }}>
                            Fragebogen "bereichsübergreifende Grundsätze des ESF Plus":
                        </Typography>
                        <Typography variant="body1" color="error" gutterBottom>
                            ACHTUNG: Mindestens eine Frage erfordert ein „Ja", restlichen Fragen mit „Nein" kennzeichnen
                        </Typography>

                        {/* Genderkompetenz */}
                        <FormControl component="fieldset" margin="normal" >
                            <FormLabel component="legend">Wird im Vorhaben kontinuierlich Genderkompetenz entwickelt?</FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderCompetence.fortbildung}
                                        onChange={(event) => handleMultipleSelection('genderCompetence', 'fortbildung', event.target.checked)}
                                        name="fortbildung"

                                    />
                                }
                                label="Ja, durch Fortbildungen für Mitarbeiter*innen"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderCompetence.sensibilisierung}
                                        onChange={(event) => handleMultipleSelection('genderCompetence', 'sensibilisierung', event.target.checked)}
                                        name="sensibilisierung"
                                    />
                                }
                                label="Ja, durch Sensibilisierung der Teilnehmer*innen"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderCompetence.nein}
                                        onChange={(event) => handleMultipleSelection('genderCompetence', 'nein', event.target.checked)}
                                        name="nein"
                                    />
                                }
                                label="Nein"
                            />
                        </FormControl>

                        {/* Geschlechterparität */}
                        <FormControl component="fieldset" margin="normal">
                            <FormLabel component="legend">Wird im Vorhaben auf Geschlechterparität geachtet?</FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderParity.teams}
                                        onChange={(event) => handleMultipleSelection('genderParity', 'teams', event.target.checked)}
                                        name="teams"
                                    />
                                }
                                label="Ja, beim Zuwendungsempfänger wird auf Geschlechterparität in Teams geachtet"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderParity.partner}
                                        onChange={(event) => handleMultipleSelection('genderParity', 'partner', event.target.checked)}
                                        name="partner"
                                    />
                                }
                                label="Ja, Projektpartner*innen und Kooperationspartner*innen des Zuwendungsempfängers achten auf Geschlechterparität in ihren Organisationen"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderParity.nein}
                                        onChange={(event) => handleMultipleSelection('genderParity', 'nein', event.target.checked)}
                                        name="nein"
                                    />
                                }
                                label="Nein"
                            />
                        </FormControl>

                        {/* Gendersensible Ansätze */}
                        <FormControl component="fieldset" margin="normal">
                            <FormLabel component="legend">Werden im Vorhaben gendersensible Ansätze umgesetzt?</FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderSensitiveApproaches.ansprache}
                                        onChange={(event) => handleMultipleSelection('genderSensitiveApproaches', 'ansprache', event.target.checked)}
                                        name="ansprache"
                                    />
                                }
                                label="Ja, bei der Ansprache oder beim Zugang zu den Zielgruppen"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderSensitiveApproaches.didaktisch}
                                        onChange={(event) => handleMultipleSelection('genderSensitiveApproaches', 'didaktisch', event.target.checked)}
                                        name="didaktisch"
                                    />
                                }
                                label="Ja, in didaktischen und/oder Beratungskonzepten"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderSensitiveApproaches.beruf}
                                        onChange={(event) => handleMultipleSelection('genderSensitiveApproaches', 'beruf', event.target.checked)}
                                        name="beruf"
                                    />
                                }
                                label="Ja, durch Aktivität zur Überwindung der geschlechterspezifischen beruflichen Segregation und des eingeschränkten Berufswahlverhaltens der Geschlechter"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderSensitiveApproaches.digital}
                                        onChange={(event) => handleMultipleSelection('genderSensitiveApproaches', 'digital', event.target.checked)}
                                        name="digital"
                                    />
                                }
                                label="Ja, durch gendersensible Ansätze zur Entwicklung von digitalen Kompetenzen"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.bafa.genderSensitiveApproaches.nein}
                                        onChange={(event) => handleMultipleSelection('genderSensitiveApproaches', 'nein', event.target.checked)}
                                        name="nein"
                                    />
                                }
                                label="Nein"
                            />
                        </FormControl>
                        <br />
                        <br />

                        <br />

                        <Button onClick={handleSubmit} fullWidth variant='outlined'>Alle Dokumente herunterladen</Button>

                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ width: '100%', paddingBottom: '50px' }}>
            {activeStep !== 0 &&
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            }



            <Box sx={{ padding: 3 }}>
                {renderStepContent(activeStep)}
            </Box>

        </Box>
    );
}

export default MultiStepForm;
