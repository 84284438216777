// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Container, CssBaseline } from '@mui/material';
import MultiStepForm from './MultiStepForm';
import styled from 'styled-components';
import './index.css'

const Heading = styled.h2`
  text-align: center;
  padding-top: 24px;
  font-size: 35px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
`;

function App() {
  return (
    <Router>
      <CssBaseline />
      <Container maxWidth="md" sx={{ marginTop: '20px' }}>
        <Heading>
          Datenerfassung für BAFA-Beratung
        </Heading>      
        <MultiStepForm />
      </Container>
    </Router>
  );
}

export default App;
